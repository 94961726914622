.navbar_Sec {
    width: auto;
    height: auto;
}

.heading {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 25px;
}

.navbar_Sec_T {
    /* position: fixed;
    top: 0; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;
    padding: 20px;
    background: #4b144b;
    z-index: 9;
    color: #fff;
}

.navbar_Sec_T div {
    max-height: 80px;
    display: flex;
    gap: 30px;
    overflow: auto;
    text-align: center;
}

.navbar_Actsec {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 35px;
}

.btN {
    width: 175px;
    height: 40px;
    background: transparent;
    border: 1px solid #fff;
    padding: unset;
    box-shadow: unset !important;
}

.p-button-label {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btN :hover,
.act {
    background: #fff;
    color: #4b144b;
    font-weight: 600;
}

.fi {
    position: fixed;
    right: 25px;
    top: 73px;
    width: 85px;
    padding: unset;
}