.search_Sec {
    padding: 20px;
    width: 100%;
    height: 85vh;
    overflow: auto;
    background: #fff;
}

.t_View {
    display: flex;
    gap: 10px;
    height: 45px;
}

.jSON {
    padding: 20px;
    overflow: auto;
}

.perD {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
}

.lI {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px 0 0;
}