.automation_Sec {
    width: 100%;
    height: 85vh;
    overflow: auto;
    background: #fff;
}

.expandTemplate {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
}

.expandTemplate_Btn {
    background: #fff;
    border: 1px solid #4b144b;
    color: #4b144b;
    font-weight: 600;
    padding: unset;
    box-shadow: unset !important;
}

.expandTemplate_Btn,
.expandTemplate_Btn .p-button-label {
    width: 150px;
    height: 45px;
}

.expandTemplate_Btn :hover,
.act_Sub {
    background: #4b144b;
    color: #fff;
}

.ce_P {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.no_Data {
    padding: 20px;
    background: #f8f9fa;
    color: #705057;
    font-weight: 600;
}

.p-inputtext {
    width: 100%;
}