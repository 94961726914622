.final_Sec {
    padding: 20px;
    width: 100%;
    height: 85vh;
    overflow: auto;
    background: #fff;
}

.asd {
    flex-direction: column;
    height: auto;
    align-items: center;
    gap: 20px;
}