body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.expandTemplate_Btn {
  background: #fff;
  border: 1px solid #4b144b;
  color: #4b144b;
  font-weight: 600;
  padding: unset;
  box-shadow: unset !important;
}

.expandTemplate_Btn,
.expandTemplate_Btn .p-button-label {
  width: 150px;
  height: 45px;
}

.expandTemplate_Btn :hover,
.act_Sub {
  background: #4b144b;
  color: #fff;
}

.p-inputtext {
  box-shadow: unset;
}

.p-dialog-header {
  text-align: center;
  border-bottom: 2px solid;
}

.p-dialog-header-icon:last-child,
.p-dialog-header {
  color: #4b144b;
}

.p-dialog-content {
  padding: 25px !important;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.align {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inPUT {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.leaBLE {
  width: 200px;
}

.ce_P {
  background: #f8f9fa;
  margin: 15px 0;
}

.hei {
  height: 20vh;
  overflow: auto;
}


.p-toast-message,
.p-toast-message-text {
  margin: unset;
}

.p-toast-message-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  background: #ffff;
}

.p-toast {
  top: 145px !important;
  width: auto;
}

.p-toast-message-success,
.p-toast-message-error {
  background: transparent;
  border-width: 0 0 0 15px;
}

.p-toast-message-icon {
  width: 20px;
}

.p-toast-message-success .p-toast-icon-close {
  background: #224a23;
  color: #fff;
}

.p-toast-message-error .p-toast-icon-close {
  background: #73000c;
  color: #fff;
}

.expandTemplate_Btn {
  width: auto !important;
}

.expandTemplate_Btn .p-button-icon {
  margin: unset;
}

.p-button {
  gap: 5px;
}

@media (max-width: 320px) {
  .lea .p-button-label {
    display: none;
  }

  .p-button {
    width: 90% !important;
  }
}