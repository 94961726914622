.login_Sec {
    width: auto;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
    font-size: 65px;
    letter-spacing: 5px;
    flex-direction: column;
}

.box {
    width: 50%;
    min-width: 200px;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 0 0 20px 0;
}

.section {
    width: 70%;
    height: auto;
    margin: auto;
    border-radius: 8px;
    border: 2px solid #4b144b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
}

.sS {
    position: relative;
    width: 100%;
    padding: 0 0 60px;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

@media (max-width: 993px) {
    .section {
        font-size: 40px;
    }

    .section,
    .box {
        width: 85%;
    }

    .p-toast-message-content {
        background: whitesmoke;
    }
}


@media (max-width: 576px) {
    .section {
        font-size: 30px;
    }
}

@media (max-width: 320px) {

    .box {
        min-width: unset;
    }

    .section {
        font-size: 25px;
    }
}